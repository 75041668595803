import React from "react";
import Intro from "../Components/Intro";
import About from "../Components/About";
import Tokenomics from "../Components/Tokenomics";
import Process from "../Components/Process";
import Roadmap from "../Components/Roadmap";
import Faqs from "../Components/Faqs";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

export default function Home() {
  return (
    <>
      <Header />
      <Intro />
      <About />
      <Tokenomics />
      <Process />
      <Roadmap />
      <Faqs />
      <Footer />
    </>
  );
}
