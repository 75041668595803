import React from "react";
import { useInView } from "react-intersection-observer";
import TokenomicsCard from "./TokenomicsCard";
import { FaTelegram, FaXTwitter } from "react-icons/fa6";

export default function Tokenomics() {
  const tokenomics = [
    {
      title: "Token Name",
      text: "$GROQ",
      description:
        "$GROQ is the native token of the GROQ AI ecosystem, used for governance and utility across the platform.",
      image: "gd-circle.png",
    },
    {
      title: "Token Chain",
      text: "ETH (ERC20)",
      description:
        "$GROQ is built on the Ethereum blockchain, utilizing the ERC20 standard for broad accessibility and security.",
      image: "eth.png",
    },
    {
      title: "Total Supply",
      text: "6,900,000,000",
      description:
        "The total supply of $GROQ is capped at 6,900,000,000 tokens, ensuring scarcity and value retention for holders.",
      image: "dollar.png",
    },
    {
      title: "Token Holders",
      text: "668",
      description:
        "With 668 holders, the $GROQ token presents a growing community of early adopters",
      image: "tag.png",
    },
  ];

  // Using useInView hooks to detect visibility of the top and bottom sections
  const { ref: topSectionRef, inView: topInView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const { ref: bottomSectionRef, inView: bottomInView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const { ref: caRef, inView: caView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <div className="wrap px-5 lg:px-10 py-20 relative" id="tokenomics">
      {/* Top section that slides in from the left */}
      <div
        ref={topSectionRef}
        className={`grid lg:grid-cols-2 gap-5 items-center ${
          topInView ? "slide-in-left" : "slide-out-left"
        }`}
      >
        <div className="wrap relative">
          <h1 className="text-5xl font-bold text-white">Tokenomics</h1>
          <img src="/assets/divider.png" alt="Image" className="my-1" />
          {/* <p className="text-white text-opacity-50">
            Lorem ipsum dolor sit amet consectetur. Praesent cras eu at
            consequat elit quis odio magna. Vitae rhoncus urna vel velit nulla.
            Dolor dui leo et morbi. Nisl commodo posuere auctor consectetur
            pharetra neque eget viverra. Ultricies amet nisl pulvinar magna. Sit
            nullam malesuada id vitae nibh nec. Dui turpis sed ac bibendum
            aliquam. Consequat dui aliquet quis aliquam sed. In fames in ma
          </p> */}

          <div className="tokenomics text-white text-opacity-70">
            <h2>Community Run Project</h2>

            <p>0/0 TAX BURNT RENOUNCED</p>

            <ul className="list-disc pl-5">
              <li>
                <span role="img" aria-label="checkmark">
                  ✔️
                </span>{" "}
                <a
                  href="https://etherscan.io/address/0x3d330B8d4EB25B0933e564D7284D462346D453Ef"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500"
                >
                  Scan CA on etherscan
                </a>
              </li>
              <li>
                <span role="img" aria-label="checkmark">
                  ✔️
                </span>{" "}
                2% Token burn done!
              </li>
              <li>
                <span role="img" aria-label="checkmark">
                  ✔️
                </span>{" "}
                LP locked
              </li>
            </ul>

            <h3>Recent News:</h3>
            <p>
              Video proof above shows GROQ AI is FASTER than Elon Musk's GROK
            </p>

            <p>
              Letter sent to Elon Musk by GROQ AI CEO:
              <a
                href="https://groq.com/hey-elon-its-time-to-cease-de-grok/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-yellow-500"
              >
                https://groq.com/hey-elon-its-time-to-cease-de-grok/
              </a>
            </p>
          </div>

          <div className="btn-wrap flex">
            <div className="p-[1px] bg-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 rounded-lg mt-5">
              <button className="text-sm bg-secondary text-white p-2 px-5 rounded-lg w-full flex justify-center items-center gap-2">
                How to buy
              </button>
            </div>
          </div>
        </div>
        <div
          ref={caRef}
          className={`wrap text-sm text-yellow-500 mx-auto px-5 text-center mt-10 bg-yellow-500 bg-opacity-20 p-5 ${
            caView ? "slide-in-right" : "slide-out-right"
          }`}
        >
          <h1 className="font-bold text-white mb-5 text-3xl">GROQ Token</h1>
          <span>CA: 0x3d330B8d4EB25B0933e564D7284D462346D453Ef</span>

          <div className="btn-wrap flex justify-center mt-3 gap-5 items-center text-white">
            <div className=" bg-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 rounded-full">
              <a href="https://t.me/GroqERCCommunity">
                <FaTelegram size={35} />
              </a>
            </div>
            <div className="p-[5px] bg-black rounded-lg">
              <a href=" https://x.com/cryptobri_/status/1745847744357388293?s=46&t=HvdVLpdxhHHTGZ2R8R4ONA">
                <FaXTwitter size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom section that slides in from the right */}
      <div
        ref={bottomSectionRef}
        className={`flex overflow-x-auto overflow-y-hidden h-auto min-h-[500px] hide-scrollbar mt-5 ${
          bottomInView ? "slide-in-right" : "slide-out-right"
        }`}
      >
        <div className="flex flex-nowrap space-x-4 gap-32 pl-[200px]">
          {tokenomics.map((i, index) => (
            <div className="wrap w-[40%] min-w-[40%]" key={index}>
              <TokenomicsCard
                img={i.image}
                title={i.title}
                text={i.text}
                description={i.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
