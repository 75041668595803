import React from "react";

export default function Footer() {
  return (
    <div className=" border-t text-white border-white border-opacity-10 text-center py-10 font-bold">
      <p>
        &copy; 2024 <span className="text-pink-600">$GROQ-TOKEN</span>. All
        Right Reserved
      </p>
    </div>
  );
}
