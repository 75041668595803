import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

export default function Process() {
  const process = [
    {
      img: "/metamask.png",
      text: "Set up and fund your Metamask wallet.",
    },
    {
      img: "/uni.png",
      text: "Buy the token on Uniswap or other DEX.",
    },
    {
      img: "/dex.png", // Assuming a new image for charts
      text: "Check charts and data on DEXScreener.",
    },
  ];

  return (
    <div className="wrap py-16 px-5">
      <div className="header flex flex-col justify-center items-center py-4">
        <h1 className="font-bold text-white text-4xl">
          <span className="text-pink-500">How </span>
          <span className="text-cyan-500">To </span>
          <span className="text-blue-500">Buy</span>
        </h1>
        <img src="/assets/divider.png" alt="Image" className="my-1 mx-auto" />
      </div>

      <div className="grid lg:grid-cols-3 lg:w-[80%] px-5 mx-auto gap-8">
        {process.map((i, index) => (
          <FadeInComponent
            key={index}
            index={index}
            img={i.img}
            text={i.text}
          />
        ))}
      </div>
    </div>
  );
}

function FadeInComponent({ index, img, text }) {
  const { ref, inView } = useInView({
    threshold: 0.1, // Trigger when 10% of the item is in view
    triggerOnce: true, // Only trigger once
  });

  return (
    <div
      ref={ref}
      className={`relative wrap rounded-tr-[60px] rounded-xl border border-white border-opacity-30 text-center overflow-hidden ${
        inView ? "fade-in" : "fade-out"
      }`}
    >
      <div className="bg-con relative z-10 backdrop-blur-3xl px-5 py-5">
        <div className="img-wrap">
          <img
            src={`/assets/${img}`}
            alt="meta"
            className="mx-auto bg-black rounded-full max-w-[80px]"
          />
          <img src="/assets/Line.png" alt="meta" className="mx-auto my-3" />
        </div>

        <span className="text-white text-opacity-70 text-sm">{text}</span>
      </div>

      <img
        src={`/assets/p-${index + 1}.png`}
        alt="p-1"
        className="absolute right-0 bottom-0 w-[80px] "
      />
    </div>
  );
}
