import React from "react";

export default function TokenomicsCard({ img, title, text, description }) {
  return (
    <div className="card border border-white border-opacity-40 p-5 rounded-xl h-[300px]">
      <div className="flex flex-col justify-between h-full ">
        <div className="flex justify-between">
          <div className="wrap text-white">
            <span>{title}</span>
            <h1 className="text-3xl font-bold mt-5">{text}</h1>
          </div>

          <div className="wrap">
            <img
              src={`/assets/${img}`}
              alt="gd Circle"
              className="w-[50px] blur-[2px]"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 relative">
          <div className="img-wrap">
            <img
              src={`/assets/${img}`}
              alt="gd Circle"
              className="absolute left-[-20%] top-5"
            />
          </div>

          <div className="wrap">
            <p className="text-sm text-white text-opacity-80">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
