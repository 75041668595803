import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

export default function About() {
  const [textInView, setTextInView] = useState(false);
  const [imageInView, setImageInView] = useState(false);

  const { ref: textRef, inView: isTextInView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) setTextInView(true);
    },
  });

  const { ref: imageRef, inView: isImageInView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) setImageInView(true);
    },
  });

  return (
    <div
      className="wrap px-5 lg:px-10 pb-20 overflow-hidden relative"
      id="about"
    >
      <img
        src="/assets/abt-blur.png"
        alt="circle"
        className="absolute inset-0 my-auto"
      />
      <div className="grid lg:grid-cols-2 gap-5 items-center">
        {/* Text section */}
        <div
          ref={textRef}
          className={`wrap relative ${
            textInView ? "slide-in-left" : "slide-out-left"
          }`}
        >
          <h1 className="text-5xl font-bold text-white">About GROQ Token</h1>
          <img src="/assets/divider.png" alt="Image" className="my-1" />
          <p className="text-white text-opacity-50">
            Missed out on GROK? Here is your chance to buy GROQ! The better AI
            version of GROK. Groq inc was founded in 2016 way before grok was
            released! Groq and xAI are in different, but related businesses.
            Groq is making the chips that make it possible for any generative AI
            model to be insanely fast (their demo is on Llama 2), but they’re
            both in the AI business.
          </p>
        </div>

        {/* Image section */}
        <div
          ref={imageRef}
          className={`wrap relative flex justify-center ${
            imageInView ? "slide-in-right" : "slide-out-right"
          }`}
        >
          <img
            src="/assets/intro-circle.png"
            alt="circle"
            className="absolute inset-0 m-auto w-[70%]"
          />
          <img
            src="/assets/groq.png"
            alt="Image"
            className="w-[70%] relative z-20"
          />
        </div>
      </div>
    </div>
  );
}
