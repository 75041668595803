import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

export default function Roadmap() {
  const [phase1InView, setPhase1InView] = useState(false);
  const [phase2InView, setPhase2InView] = useState(false);
  const [phase3InView, setPhase3InView] = useState(false);
  const [imageInView, setImageInView] = useState(false);

  const { ref: phase1Ref, inView: isPhase1InView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) setPhase1InView(true);
    },
  });

  const { ref: phase2Ref, inView: isPhase2InView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) setPhase2InView(true);
    },
  });

  const { ref: phase3Ref, inView: isPhase3InView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) setPhase3InView(true);
    },
  });

  const { ref: imageRef, inView: isImageInView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) setImageInView(true);
    },
  });

  return (
    <div className="wrap relative px-5 lg:px-10" id="roadmap">
      <img src="/assets/rd.png" alt="rd" className="absolute inset-0 m-auto" />
      <div className="header">
        <div className="header flex flex-col justify-center items-center py-4 text-center lg:w-1/2 mx-auto">
          <h1 className="font-bold text-white text-4xl">$GROQ Roadmap</h1>
          <span className="text-sm text-white text-opacity-70 mt-4">
            The $GROQ roadmap outlines key milestones for the growth and
            development of the GROQ AI ecosystem. From expanding our technology
            to building strategic partnerships.
          </span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center mt-10">
        <div className="wrap lg:w-[60%]">
          {/* Phase 1 */}
          <div
            ref={phase1Ref}
            className={`road-map flex gap-5 mt-5 text-sm ${
              phase1InView ? "slide-in-left" : "slide-out-left"
            }`}
          >
            <div className="wrap flex flex-col items-center justify-center h-full">
              <img src="/assets/p-1.png" alt="p-1" className="w-[30px]" />
              <img src="/assets/Line2.png" alt="p-1" className="h-[100px]" />
            </div>
            <div className="text-wrap">
              <h1 className="font-bold text-white text-2xl">
                Q4 2023: Project Initiation
              </h1>
              <ul className="text-white text-opacity-70 leading-8 mt-5">
                <li class="relative pl-8 before:content-['✓'] before:text-green-500 before:absolute before:left-0">
                  Concept Development: Define the mission and vision of GROQ
                </li>

                <li class="relative pl-8 before:content-['✓'] before:text-green-500 before:absolute before:left-0">
                  Community Building: Launch social media channels and engage
                  with potential users and investors.
                </li>
              </ul>
            </div>
          </div>

          {/* Phase 2 */}
          <div
            ref={phase2Ref}
            className={`road-map flex gap-5 mt-5 text-sm ${
              phase2InView ? "slide-in-left" : "slide-out-left"
            }`}
          >
            <div className="wrap flex flex-col items-center justify-center h-full">
              <img src="/assets/p-2.png" alt="p-1" className="w-[30px]" />
              <img src="/assets/Line2.png" alt="p-1" className="h-[300px]" />
            </div>
            <div className="text-wrap">
              <h1 className="font-bold text-white text-2xl">
                Q1-Q2 2024: Token Development
              </h1>
              <ul className="text-white text-opacity-70 leading-8 mt-5">
                <li class="relative pl-8 before:content-['✓'] before:text-green-500 before:absolute before:left-0">
                  Smart Contract Development: Code and audit the GROQ smart
                  contract to ensure security and functionality.
                </li>

                <li class="relative pl-8 before:content-['✓'] before:text-green-500 before:absolute before:left-0">
                  Tokenomics Finalization: Finalize the distribution model,
                  including initial supply, allocation, and vesting schedules.
                </li>

                <li class="relative pl-8 before:content-['✓'] before:text-green-500 before:absolute before:left-0">
                  Public Token Sale: Organize a public sale via a trusted
                  platform or directly through the website.
                </li>
                <li class="relative pl-8 before:content-['✓'] before:text-green-500 before:absolute before:left-0">
                  CTO - Community Takeover
                </li>

                <li class="relative pl-8 before:content-['✓'] before:text-green-500 before:absolute before:left-0">
                  initial Website Launch: Develop and launch a professional
                  website with project details and roadmap updates.
                </li>
              </ul>
            </div>
          </div>

          {/* Phase 3 */}
          <div
            ref={phase3Ref}
            className={`road-map flex gap-5 mt-5 text-sm ${
              phase3InView ? "slide-in-left" : "slide-out-left"
            }`}
          >
            <div className="wrap flex flex-col items-center justify-center h-full">
              <img src="/assets/p-3.png" alt="p-1" className="w-[30px]" />
              <img src="/assets/Line2.png" alt="p-1" className="h-[300px]" />
            </div>
            <div className="text-wrap">
              <h1 className="font-bold text-white text-2xl">
                Q3 -Q4 2024: Initial Funding and Launch
              </h1>

              <ul className="text-white text-opacity-70 leading-8 mt-5">
                <li class="relative pl-8 before:content-['-'] before:text-green-500 before:absolute before:left-0">
                  Community Engagement: Implement community initiatives to grow
                  community and awareness of GROQ on ETH Token
                </li>

                <li class="relative pl-8 before:content-['-'] before:text-green-500 before:absolute before:left-0">
                  Multilingual Support: Expand community support and resources
                  to non-English speakers.
                </li>

                <li class="relative pl-8 before:content-['-'] before:text-green-500 before:absolute before:left-0">
                  Partnerships: Establish partnerships with other projects and
                  platforms in the crypto space.
                </li>

                <li class="relative pl-8 before:content-['-'] before:text-green-500 before:absolute before:left-0">
                  Development of Use Cases: Create initial use cases for GROQ,
                  such as staking, governance, or utility in decentralized
                  applications (dApps).
                </li>

                <li class="relative pl-8 before:content-['-'] before:text-green-500 before:absolute before:left-0">
                  Ecosystem Expansion: Develop additional features, such as a
                  wallet integration or decentralized finance (DeFi) solutions.
                </li>

                <li class="relative pl-8 before:content-['-'] before:text-green-500 before:absolute before:left-0">
                  And More...
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Main Image */}
        <div
          ref={imageRef}
          className={`img-wrap ${
            imageInView ? "slide-in-left" : "slide-out-left"
          }`}
        >
          <img src="/assets/rd-img.png" alt="rd" className="w-[500px]" />
        </div>
      </div>
    </div>
  );
}
