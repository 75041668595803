import React from "react";
import { useInView } from "react-intersection-observer";
import FaqCard from "./FaqCard";

export default function Faqs() {
  const faqs = [
    {
      question: "What is $GROQ?",
      answer:
        "$GROQ is a meme-based cryptocurrency created for fun, community engagement, and entertainment. It's a token with a strong community behind it, aiming to make waves in the crypto world through collective support.",
    },
    {
      question: "How do I buy $GROQ?",
      answer:
        "To buy $GROQ, you'll need a wallet like MetaMask. Connect your wallet to a decentralized exchange (DEX) such as Uniswap, search for $GROQ, and swap your ETH for $GROQ. Make sure you have enough ETH for gas fees.",
    },
    {
      question: "What makes $GROQ special?",
      answer:
        "GROQ is the original fan token of the most amazing technology in the AI space, GROQ AI! Our community is passionate about AI, and how it’s going to change the world for the better!",
    },
  ];

  // Hooks to observe the first and second grids
  const { ref: firstGridRef, inView: firstGridInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: secondGridRef, inView: secondGridInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div className="wrap px-8 py-16">
      <div className="grid lg:grid-cols-2 gap-10 items-center">
        {/* First Grid - Heading Section */}
        <div
          ref={firstGridRef}
          className={`wrap ${
            firstGridInView ? "slide-in-left" : "slide-out-left"
          }`}
        >
          <h1 className="lg:block hidden text-3xl font-bold text-white">
            Frequently Asked <br />
            <span className="text-primary-green">Question (FAQs)?</span>
          </h1>

          <h1 className="lg:hidden font-bold text-2xl text-center leading-[60px] text-cyan-300">
            (FAQs)?
          </h1>
          <img src="/assets/divider.png" alt="Image" className="my-3" />

          <span className="text-sm text-gray-400 mt-4">
            Find answers to common questions about $GROQ, tokenomics, and how to
            get involved in the project. If you can't find what you're looking
            for, feel free to reach out!
          </span>
        </div>

        {/* Second Grid - FAQs Section */}
        <div
          ref={secondGridRef}
          className={`wrap flex flex-col gap-5 ${
            secondGridInView ? "slide-in-right" : "slide-out-right"
          }`}
        >
          {faqs.map((i, index) => (
            <FaqCard question={i.question} answer={i.answer} key={index} />
          ))}
        </div>
      </div>

      <div className="wrap text-sm text-yellow-500 lg:w-1/2 mx-auto px-5 text-center mt-10 bg-yellow-500 bg-opacity-20 p-5">
        <span>
          We are NOT affiliated with the company Groq AI, but are the original
          Groq fan token! The original Groq Token on Ethereum network!
        </span>
      </div>
    </div>
  );
}
