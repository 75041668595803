import React, { useState } from "react";
import { FaTelegram, FaXTwitter } from "react-icons/fa6";
import { useInView } from "react-intersection-observer";

export default function Intro() {
  const [hasFadedIn, setHasFadedIn] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.1, // Trigger when 10% of the component is visible
    triggerOnce: true, // Only trigger once
    onChange: (inView) => {
      if (inView) {
        setHasFadedIn(true); // Set fade-in state to true when in view
      }
    },
  });

  return (
    <div
      ref={ref}
      className={`wrap bg-secondary text-center py-32 relative transition-opacity duration-1000 ${
        hasFadedIn ? "opacity-100" : "opacity-0"
      }`}
    >
      <img
        src="/assets/intro-circle.png"
        alt="circle"
        className="absolute inset-0 m-auto w-[70%] spin"
      />
      <img
        src="/assets/intro-blur.png"
        alt="circle"
        className="absolute inset-0 m-auto"
      />
      <div className="flex flex-col gap-5 justify-center items-center mx-auto lg:w-[60%] text-white relative z-20">
        <h1 className="text-4xl lg:text-6xl font-bold">
          <div className="p-[2px] bg-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 rounded-lg text-black">
            $GROQ
          </div>{" "}
          AI IS KING!
        </h1>
        <span className="text-sm text-white text-opacity-50 px-5">
          GROQ Token on ETH is the first and only original community of GROQ AI
          enthusiasts who want bring awareness to GROQ AI and the amazing things
          they are doing to change the world!
        </span>

        <div className="btn-wrap flex gap-5 items-center">
          <div className="p-[1px] bg-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 rounded-lg">
            <a href="https://app.uniswap.org/#/swap?outputCurrency=0x3d330B8d4EB25B0933e564D7284D462346D453Ef">
              <button className="text-sm bg-secondary text-white p-2 px-5 rounded-lg w-full flex justify-center items-center gap-2">
                Buy $GROQ now
              </button>
            </a>
          </div>

          <div className="p-[2px] bg-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 rounded-lg">
            <a href="https://t.me/GroqERCCommunity">
              <FaTelegram size={30} />
            </a>
          </div>
          <div className="p-[2px] bg-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 rounded-lg">
            <a href=" https://x.com/cryptobri_/status/1745847744357388293?s=46&t=HvdVLpdxhHHTGZ2R8R4ONA">
              <FaXTwitter size={30} />
            </a>
          </div>
        </div>

        <div className="image-banner">
          <img src="/assets/intro-img.png" alt="Intro" className="rounded-xl" />
        </div>
      </div>
    </div>
  );
}
